.searchBaHeader {
  background: #f8f8f8;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  padding-bottom: 5px;
  position: relative;
  z-index: 105;
}

.searchBarContents {
  width: 100%;
  padding: 0px 15px;
}

.serachBarBackGroundColor {
  background: #e6e6e6 !important;
}

.searchBarCursor {
  cursor: pointer;
}

.searchBarButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow: auto; */
}

.searchBardropDownMenu {
  position: absolute !important;
  margin-left: -120px !important;
}

.searchBarOrdersOptions {
  display: flex;
  align-items: center;
}

.searchBartransactionOptions {
  display: flex;
  align-items: center;
}

.searchBarSearchButtons {
  display: flex;
}

.searchBarDropDownOptions {
  margin-left: 8px;
  font-size: 0.8125rem;
}

.searchBarSearch {
  display: flex;
  align-items: center;
}

.searchBarSearchInput {
  height: 35px !important;
  border: 1px solid #9d9d9d !important;
}

.searchBarClearSearch {
  display: flex;
  margin-right: -15px;
}

.searchBarOrderListDropDown {
  display: flex;
  gap: 10%;
  align-items: center;
  margin-top: -7px;
}

.searchBarMethods {
  display: flex;
  align-items: center;
  margin-top: -8px;
}

.searchBarTransactionDropDown {
  display: flex;
  gap: 10%;
  align-items: center;
  margin-top: -15px;
  margin-left: -32px;
}

.searchBarLocationDropDown {
  /* display: flex; */
  margin-top: -7px;
  margin-bottom: 10px;
}

.searchBarCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 15px;
}

.searchBarShowCard {
  display: flex;
}

.searchBarDropDown {
  display: flex;
  align-items: center;
}

.searchBarTestDropDown {
  display: flex;
  /* align-items: center; */
  gap: 15px;
  margin: 4px 0px 0px 30px;
  /* margin-left: 30px;
  margin-top: 4px; */
}

.searchBarTestDropDownLabel {
  margin-top: 2px;
}

@media (max-width: 500px) {
  .searchBaHeader {
    background-color: #f8f8f8;
    /* overflow-x: auto;
    overflow-y: hidden; */
    font-size: 13px;
  }

  .searchBarContents {
    width: 100%;
  }

  .searchBarSearchInput {
    width: 65% !important;
  }

  .searchBarShowCard {
    display: flex;
    /* position: absolute;
    margin-left: 175px;
    background: #f8f8f8;
    border-radius: 30px; */
  }

  .searchBarButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    width: 75%;
    margin-left: -25px;
  }

  .searchBarButtons button {
    white-space: nowrap;
  }

  .searchBarSearchButtons {
    display: flex;
    position: absolute;
    margin-left: 215px;
  }

  .searchBarOrderListDropDown {
    background: transparent;
    display: contents;
  }

  .searchBarSearch {
    background: transparent;
    /* display: contents; */
    margin-left: -25px;
    justify-content: space-between;
    position: relative;
    z-index: 110;
  }

  .searchBarMethods {
    display: flex;
    align-items: center;
    margin-top: -15px;
    justify-content: space-evenly;
  }

  .searchBarFilters {
    min-width: auto !important;
  }

  .searchBarLocationDropDown {
    margin-top: -5px;
    /* display: flex; */
    margin-left: -25px;
    width: 70%;
  }

  .searchBardropDownMenu {
    position: absolute !important;
    margin-left: -100px !important;
    min-width: auto !important;
  }

  .searchBarCancel {
    margin-right: -25px;
    z-index: 0;
  }

  .searchBarSearchAndSortButton {
    margin-right: -25px;
  }

  .searchBarDropDown {
    display: flex;
    align-items: center;
  }
}
