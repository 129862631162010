.orderDropDownOptions {
  min-width: auto !important;
  margin-left: -60px !important;
}

.orderLocationName {
  font-size: 20px;
  font-weight: 700;
  text-align: end;
}

@media (max-width: 500px) {
  .orderDetails {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .orderDeatilsButtonGroup {
    margin-left: -12px;
  }

  .orderDeatilsButton {
    font-size: 12px !important;
  }

  .orderDateAndTime {
    font-size: 13px;
  }

  .orderLocationName {
    font-size: 13px;
    font-weight: 700;
    text-align: end;
  }

  .orderOpitons {
    margin-left: 40px !important;
    margin-top: -15px !important;
  }

  .orderDropDownOptions {
    min-width: auto !important;
    margin-left: -70px !important;
  }
}
