/* .header {
  align-items: center;
} */

/* .buttons {
  display: flex;
  align-items: center;
  justify-content: end;
} */
.orderDropDownOptions {
  min-width: auto !important;
}

@media (max-width: 500px) {
  .header {
    display: grid !important;
    font-size: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .column {
    display: flex;
    align-items: center;
  }

  .column h6 {
    margin-left: 10px;
    white-space: nowrap;
    font-size: 16px;
  }

  .buttons {
    display: flex;
    margin-top: 0px !important;
  }

  .orderDropDownOptions {
    min-width: auto !important;
  }
}
