.react-datepicker-wrapper{
    width: 100% !important;
}
.textAlign{
    align-self: center !important;
}
.paddingTop5{
    padding-top: 5px !important;
}
.paddingBottom5{
    padding-bottom: 5px !important;
}
.paddingBottom0{
padding-bottom: 0px !important;
}
.justifyCenter{
    justify-content:center !important;
}
.textcenter{
    text-align: center !important;
}
.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 88px;
    top: -16px;
}
.react-datepicker__month-container {
    float: left;
    width: 277px;
}
.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    width: 367px;
    margin-left: -60px;
    position: relative;
}
.activebtn{
    background-color: lightblue !important;
    color: white !important;
}