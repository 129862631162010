.heading {
  margin-left: 10px;
  white-space: nowrap;
}

.tableHeight {
  height: 600px;
  overflow-y: scroll;
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 100;
}

.suggestions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.suggRadioBtn {
  margin-top: -6px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: end;
}

.header {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .heading {
    font-size: 13px !important;
  }
}
