.calender1 {
  z-index: 110;
}
.calender {
  z-index: 110;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 50px;
}
.client_facing {
  /* white-space: nowrap; */
  font-size: 25px;
  display: flex;
}
.sku_calender {
  z-index: 110;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.sku_report {
  font-size: 25px;
  display: flex;
}
.buttonStyle011 {
  display: none;
}
.order_missing_button_desktop {
  margin-right: 50px;
  display: flex;
}
.order_missing_button_mobile {
  display: none;
}
.order_missing_header {
  height: 80px;
  border: 2px solid #f6f9fc;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding-left: 20px;
  margin-left: 5px;
}
.orderid_discliamer_cont {
  display: flex;
  flex-direction: column;
}
.orderid_discliamer_text {
  font-size: 12px;
  font-weight: 700;
  margin-left: 40px;
  color: red;
  margin-top: 8px;
  text-transform: none !important;
}
.orderid_heading {
  font-size: 18px;
  font-weight: 700;
  margin-left: 40px;
}
@media (max-width: 500px) {
  .calender1 {
    order: 2;
    white-space: nowrap;
    margin-right: 60px;
    /* position: absolute; */
    /* top: 25px; */
  }

  .client_facing {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .calender {
    flex-direction: column;
  }
  .client_facing_header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_header1 {
    height: 100px;
  }
  .sku_calender {
    gap: 30px;
  }
  .sku_calender1 {
    /* white-space: nowrap; */
    width: 100%;
  }
  .sku_report {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
  }
  .ant-picker-panels {
    flex-direction: column;
  }
  .buttonStyle01 {
    display: none;
  }
  .button_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .buttonStyle011 {
    display: flex;
    margin-top: 10px;
    /* margin-bottom: 12px; */
  }
  .order_missing_button_desktop {
    display: none;
  }
  .order_missing_button_mobile {
    margin-left: 20px;
    display: flex;
  }
  .orderid_discliamer_text {
    margin-left: 15px;
  }
  .orderid_heading {
    margin-left: 15px;
  }
}
