.aliasName {
  display: grid;
  grid-template-columns: 0.15fr 1fr;
}
.aliasInput {
  width: 40%;
}
.aliasInput_doordash {
  width: 40%;
}
.aliasDoordash {
  margin-top: 10px;
  margin-left: 60px;
  margin-right: 30px;
}
.aliasContainer {
  display: flex;
}
.orderid_discliamer_text {
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  color: red;
}
.sales_tax_remittance_text {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
  font-size: 1.0625rem;
}
@media (max-width: 500px) {
  .buttonGroup {
    margin-left: -12px;
    display: flex !important;
    overflow: auto;
    white-space: nowrap;
  }

  .button {
    font-size: 12px !important;
  }

  .tabPaneRow {
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .tabPaneRowHoliday {
    width: 50% !important;
  }

  .tabPaneRow h5 {
    margin-top: 0.5rem !important;
  }

  .tabPaneRow div {
    width: auto !important;
    padding-right: 0px !important;
  }

  .tabPaneRow select {
    padding: 0.625rem 0.25rem;
    font-size: 0.8rem;
    width: 100px;
    height: auto;
  }

  .tabPaneRow > div > div {
    margin-bottom: 0px;
  }

  .dateAndTime > div {
    font-size: 10px;
  }

  .dateAndTime input {
    width: 100%;
  }

  .dateAndTime {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dateAndTimePicker {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding-left: 15px;
    padding-right: 5px;
  }
  .aliasName {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .aliasInput {
    width: 100%;
  }
  .aliasInput_doordash {
    width: 100%;
    display: none;
  }
  .aliasContainer {
    display: grid;
  }
  .aliasDoordash {
    display: none;
  }
}

.lat_input::-webkit-outer-spin-button,
.lat_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
