/* Example Styles for React Tags*/

/* Example Styles for React Tags*/

.container {
  margin: auto;
  width: 50%;
}
.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 45px;
  margin: 0;
  font-size: 15px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  background: #ececec;
  color: black;
  font-size: 13px;
  display: inline-block;
  padding: 5px;
  margin: 10px 5px;
  border-radius: 15px;
  padding-left: 10px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  left: 0;
  width: 50%;
  z-index: 100;
}
.ReactTags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  margin: 0px 5px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: box-shadow 0.3s ease-in-out;
}

/* Hover styles for the remove button */
.ReactTags__remove:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
}
