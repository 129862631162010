.container {
  padding: 0px !important;
  margin: 0px -20px 0px -20px !important;
  /* width: 100% !important; */
  font-size: 14px;
}

@media (max-width: 500px) {
  .searchBarOrderList {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
