.transactionList {
  padding: 0px !important;
  margin: 0px -20px 0px -20px !important;
  /* width: 100% !important; */
  font-size: 14px;
}

.tableContent {
  height: 600px;
  overflow-y: scroll;
}

.tableHeaderContent {
  position: sticky;
  top: 0px;
  z-index: 100;
}

@media (max-width: 500px) {
  .searchBarTransactionList {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .tableContent {
    height: 580px;
    overflow-y: scroll;
  }

  .tableHeaderContent {
    position: sticky;
    top: 0px;
    z-index: 100;
  }

  .buttonStyle {
    margin-top: -10px !important;
    white-space: nowrap;
  }
}
