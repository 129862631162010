.tableContent {
  height: 620px;
  overflow-y: scroll;
}

.tableHeaderContent {
  position: sticky;
  top: 0px;
  z-index: 100;
}

@media (max-width: 500px) {
  .tableContent {
    height: 580px;
    overflow-y: scroll;
  }

  .tableHeaderContent {
    position: sticky;
    top: 0px;
  }
}
