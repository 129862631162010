.column {
  display: flex;
  align-items: center;
}

.column h6 {
  margin-left: 10px;
  white-space: nowrap;
  font-size: 16px;
}

.imagePosition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.imageCard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 200px;
  object-fit: cover;
}
