/* Admin role permission */


.rowHorizontal {
    padding-left: .5cm;
}

.row-Margin{
    margin-left: 1.30cm;
}

.rowleftMargin{
    padding-right: .5cm;
}

.backgrounColorCheck{
    background-color: aqua;
}

.textCapitalize{
    text-transform: capitalize;
}

.top-margin-show{
        margin-top: "2.4rem";
}
.checkBoxIncrese{
    width: 20px;
    height: 20px;
}
.largerCheckbox{
    /* transform : scale(10); */
    width: 15px;
    height: 15px;
}
.vbottom {
    display: inline-block;
    vertical-align: bottom;
    /* margin-top: 320px; */
  }

  .checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
}