.heading {
  white-space: nowrap;
}

.container {
  margin-left: 15%;
}

.masterProductsDataList {
  padding: 0px !important;
  margin: 0px -20px 0px -20px !important;
  font-size: 14px;
}

.tableHeight {
  height: 550px;
  overflow-y: scroll;
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 100;
}

.dwlndBtn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.buttonStyle02 {
  white-space: nowrap;
}

.alcholicVolume {
  text-align: center;
}

.alcholic {
  text-align: center;
}

.prodImg {
  height: 70px;
  width: max-content;
}

.imageCard {
  position: relative;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editImg {
  position: absolute;
  left: 100%;
  bottom: 85%;
  font-size: 15px;
}

.uploadedImage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0.0625rem dashed rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  cursor: pointer;
}

.modalImg {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  height: 150px;
}

.inputBox {
  min-width: 100px;
}

.titleInput {
  display: flex;
}

.barcode {
  min-width: 160px;
}

.status {
  min-width: 110px;
}

.title {
  min-width: 250px;
}

.optionInput {
  min-width: 110px;
}

.errorTag {
  color: red;
  font-size: 10px;
}

.infoText {
  display: grid;
  gap: 30px;
  text-align: center;
}

.req {
  font-size: 10px;
}

.required {
  display: grid;
}

.titleGap {
  padding-bottom: 15px;
}

.tagsLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tagsLabelInfo {
  margin-bottom: 0 !important;
}

.imgSize {
  color: red;
}

@media (max-width: 500px) {
  .dwlndBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
