.productList {
  padding: 0px !important;
  margin: 0px -20px 0px -20px !important;
  /* width: 100% !important; */
  font-size: 14px;
}

.tableContent {
  height: 600px;
  overflow-y: scroll;
}

.tableHeaderContent {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.transactions_calender {
  z-index: 110;
  display: flex;
  justify-content: end;
  align-items: center;
}
.transaction_text {
  white-space: nowrap;
  font-size: 25px;
  display: flex;
}

@media (max-width: 500px) {
  .searchBarLocationList {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .tableContent {
    height: 580px;
    overflow-y: scroll;
  }

  .tableHeaderContent {
    position: sticky;
    top: 0px;
    z-index: 100;
  }

  .buttonStyle {
    /* margin-top: -10px !important; */
    white-space: nowrap;
  }

  .transactions_calender {
    flex-direction: column;
    margin: 0px 0px 0px 10px;
  }
  .transactions_calender1 {
    white-space: nowrap;
  }
}
