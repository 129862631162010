.mb {
  margin-bottom: 0;
}

.alignCenter {
  align-items: center;
}

.gap {
  margin-bottom: 0.5rem;
}

.flexGap {
  gap: 1rem;
}

.cardSize {
  max-width: 1220px;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  width: 100%;
}

.devicePreview {
  max-width: 300px !important;
}

/* . {
  width: 100%;
}

.cardSize > div > div {
  max-width: 350px;
} */
